export default function videoControls() {
   const video = document.getElementById("js-video");
   const button = document.getElementById("js-video-button");

   controlVid(video);
   controlVid(button);

   function controlVid(trigger){
     trigger.addEventListener('click', function(){
       if (video.paused) {
          video.play();
          button.textContent = '"Pause"';
          button.style.zIndex = '-1';
       } else {
          video.pause();
          button.textContent = '"Play"';
          button.style.zIndex = '2';
        } 
     });   
   }

  video.addEventListener('playing', function(){
     window.addEventListener('scroll', function(){
        video.pause();
        button.textContent = '"Play"';
        button.style.zIndex = '2';
    });
  })
} 