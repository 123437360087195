export default function parallax() {
	const controller = new ScrollMagic.Controller();

	introParallax();
	parallaxScene('.js-parallax-feature1', 1, '-150');
	parallaxScene('.js-parallax-feature2', 3, '-180');
	parallaxScene('.js-parallax-feature3', 3, '-100');
	parallaxScene('.js-parallax-feature4', 4, '-150');
	parallaxScene('.js-parallax-feature5', 1, '-150');
	parallaxScene('.js-parallax-feature6', 1, '-150');
	parallaxScene('.js-parallax-snippet', 3, '-120');

	function introParallax() {
		// get all els
		const vid = document.querySelector(".js-parallax-video"),
			title = document.querySelector(".js-parallax-title"),	
			brands = document.querySelector(".js-parallax-brands"),
			actionScroll = document.querySelector(".js-parallax-scroll"),
			actionPlay = document.querySelector(".js-parallax-play");

		// build tween
		let tween = new TimelineMax ()
			.add([
				TweenMax.to(vid, 5, {y: -600}),
				TweenMax.to(brands, 5, {y: -400}, 1),
				TweenMax.to(actionPlay, 4, {y: -600}),
				TweenMax.to(title, 2, {y: -200, autoAlpha:0, delay: 8}),			
				TweenMax.to(actionScroll, 3, {y: -150, autoAlpha: 0})
			]);

		// build scene
		let scene = new ScrollMagic.Scene({
			triggerHook: 'onEnter', 
			duration: '100%'
		})
		.setTween(tween)
		// .addIndicators()
		.addTo(controller);
	}

	function parallaxScene(item, duration, endPos, delay) {
		const el = document.querySelector(item);
		let tween;

		// rotate el
		if(!("rotate" in el.dataset)) {
			tween = TweenMax.to(el, duration, { y: endPos, delay: delay, overwrite: "all" });
		} else {
			tween = TweenMax.to(el, duration, { y: endPos, rotation: -20, delay: delay, overwrite: "all"});
		}

		// create scene for this el
		let scene = new ScrollMagic.Scene({
			triggerElement: el, 
			triggerHook: 'onEnter', 
			duration: '100%'
		})
		.setTween(tween)
		.addTo(controller);
	}
}