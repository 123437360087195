export default function overlay(){

	const overlay = document.querySelector('.js-overlay'),
		container = document.querySelector('.js-overlay-content'),
		password = document.querySelector('.js-overlay-pass'),
		submit = document.querySelector('.js-overlay-submit'),
		error = document.createElement('p');

	// add class to error p
	error.classList.add('overlay__error');

	submit.addEventListener('click', () => {
		validatePass(password);
	});

	function validatePass(pass) {
	    if(pass.value == 'tenicons'){
	    	// hide overlay
	    	window.scrollTo(0, 0);
	    	overlay.style.transform = "translateY(-100%)";
	    }else{
	    	// generate p tag that says please try again
	    	error.innerHTML = "Your password is incorrect, please try again."
	    	container.appendChild(error);
	    }
	}

}